import React from 'react';

function Commitments() {
    return (
        <>
            <div className={"bg-burnt_sienna-300 p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto shadow-xl"}>
                <div className={"bg-dutch_white-300 text-lg p-4 mx-auto w-fit rounded shadow"}>
                    <div
                        className="p-6 bg-gray-100 text-gray-800 rounded-lg shadow-md max-w-3xl mx-auto text-left mb-4">
                        <h2 className="text-xl font-bold mb-4 text-center">Promises to Our Users</h2>

                        <ul className="list-disc pl-6 space-y-2">
                            <li>Brainmeter will never have direct monetary cost to teachers or students. Our contracts will be negotiated with building administrators to provide full access to the platform to all users within their school.</li>
                            <li>Brainmeter contracts will always include professional development hours focused on how the tool functions both mechanically and instructionally.</li>
                            <li>Brainmeter will continue ongoing feature development to ensure that the platform stays relevant in a constantly
                                changing educational landscape.
                            </li>
                            <li>Brainmeter will strive to improve as an accessible platform for all learners and instructors in accordance with
                                WCAG 2.1.
                            </li>
                        </ul>
                    </div>

                    <div
                        className="p-6 bg-gray-100 text-gray-800 rounded-lg shadow-md max-w-3xl mx-auto text-left text-sm">
                        <h2 className="text-xl font-bold mb-4 text-center">Pathway Toward WCAG 2.1 Compliance</h2>
                        <p className="mb-4">We are committed to developing a fully accessible platform for all learners and instructors by adhering to WCAG 2.1 guidelines. This commitment includes:</p>
                        <ul className="list-disc pl-5 space-y-2">
                            <li>Implementing keyboard navigability to ensure all functions can be accessed without a mouse.</li>
                            <li>Adding alternative text to all images for better accessibility with screen readers.</li>
                            <li>Ensuring all content is compatible with screen reader technology.</li>
                        </ul>
                        <div className="mt-6">
                            <h3 className="text-xl font-bold mb-2 text-center">Current State</h3>
                            <p className="mb-2">As we are just starting this journey, our current focus is on planning
                                and prioritization. The list below is composed of features and accessibility goals that
                                we aim to accomplish by the beginning of 2025. As we complete each task, they will be
                                marked and time-stamped here:</p>
                            <ul className="list-disc pl-5 space-y-2">
                                <li className={'mb-2 line-through'}>Contact Form:</li>
                                <p className={'italic text-xs ml-4 mb-2'}>Completed 1/4/25</p>

                                <ul className="list-disc pl-5 mt-2 space-y-2">
                                    <li className={'mb-2 line-through'}>Accessibility Requests</li>
                                    <li className={'mb-2 line-through'}>Feature Requests</li>
                                    <li className={'mb-2 line-through'}>Bug Reports</li>
                                </ul>
                            </ul>
                            <li className={'mb-2 line-through'}>Student Homepage</li>
                            <p className={'italic text-xs ml-4 mb-2'}>Completed 10/19/24</p>
                            <ul className="list-disc pl-5 mt-2">
                                <li className={'mb-2 line-through'}>Keyboard navigation</li>
                                <p className={'italic text-xs ml-4 mb-2'}>Completed 10/19/24</p>

                                <ul className="list-disc pl-5 mt-2">
                                    <li className={'line-through'}>Home Page</li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Completed 8/17/24</p>

                                    <li className={'mb-2 line-through'}>Multiple Choice Question Answer Modals</li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Multiple Choice Completed 8/25/24</p>

                                    <li className={'mb-2 line-through'}>Sorting, Recall, and Matching Answer
                                        Modals
                                    </li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Sorting and Matching Completed
                                        9/28/24, Recall Updated 10/19/24</p>


                                    <li className={'mb-2 line-through'}>Student Data Summary</li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Completed 8/25/24</p>

                                </ul>
                                <li className={'mb-2 line-through'}>Visual contrast and clarity</li>
                                <p className={'italic text-xs ml-4 mb-2'}>Completed 8/17/24</p>
                                <li className={'mb-2 line-through'}>Support for accessibility technologies</li>
                                <p className={'italic text-xs ml-4 mb-2'}>Labelling for Voiceover Technologies
                                    Completed 8/25/24</p>

                                <li>Teacher Planning Interface</li>
                                <ul className="list-disc pl-5 mt-2 space-y-2">
                                    <li>Keyboard navigation</li>
                                    <ul className="list-disc pl-5 mt-2">
                                        <li className={'line-through'}>Standard Selection</li>
                                        <p className={'italic text-xs ml-4 mb-2'}>Completed 9/15/24</p>

                                        <li className={'mb-2 line-through'}>Text Box Navigation</li>
                                        <p className={'italic text-xs ml-4 mb-2'}>Completed 9/22/24</p>

                                        <li className={'mb-2 line-through'}>Multiple Choice and Image Multiple Choice
                                            Input
                                        </li>
                                        <p className={'italic text-xs ml-4 mb-2'}>Completed 9/15/24</p>

                                        <li className={'mb-2 line-through'}>Sorting, Recall, and Matching Assessment
                                            Item Modals
                                        </li>
                                        <p className={'italic text-xs ml-4 mb-2'}>All Assessment Item Entry with
                                            Keyboard Only Completed 9/24/24</p>

                                        <li className={'mb-2 line-through'}>Standard Overview Panel Navigation Summary
                                        </li>
                                        <p className={'italic text-xs ml-4 mb-2'}>Workflow Rebuilt for Focus Management
                                            Completed 11/30/24</p>


                                        <li className={'mb-2 line-through'}>Intelligent focus management on standard
                                            select
                                        </li>
                                        <p className={'italic text-xs ml-4 mb-2'}>Workflow Rebuilt for Focus Management
                                            Completed 11/30/24</p>

                                    </ul>
                                    <li className={'line-through'}>Visual contrast and clarity</li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Completed 9/28/24</p>

                                    <li>Support for accessibility technologies</li>
                                </ul>
                                <li>Teacher Logistics Interface</li>
                                <ul className="list-disc pl-5 mt-2 space-y-2">
                                    <li>Keyboard navigation</li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Completed, but lacks elegance 11/30/24</p>
                                    <li className={'line-through'}>Visual contrast and clarity</li>
                                    <p className={'italic text-xs ml-4 mb-2'}>Completed 11/30/24</p>
                                    <li>Support for accessibility technologies</li>
                                </ul>
                                <li>Teacher Data Interface</li>
                                <p className={'italic'}>Update: 1/4/25 -- Here, we've hit a snag in that our current
                                    library
                                    for charts lacks sufficient accessibility needs. We are investigating other
                                    solutions to
                                    make our data more accessible.</p>
                                <p className={'italic'}>Update: 1/26/25 -- We have discovered a solution that allows us to keep the existing library. Complete accessibility for Data nearly complete.</p>
                                <ul className="list-disc pl-5 mt-2 space-y-2">
                                    <li>Keyboard navigation</li>
                                    <li>Visual contrast and clarity</li>
                                    <li>Support for accessibility technologies</li>
                                </ul>
                                <li className={'line-through'}>Coordinator Unique Elements</li>
                                <p className={'italic'}>Update: 1/4/25 -- Coordinator unique elements have been
                                    eliminated in most cases, opting instead to replicate existing teacher
                                    interfaces.</p>

                                <ul className="list-disc pl-5 mt-2 space-y-2">
                                    <li className={'line-through'}>Keyboard navigation</li>
                                    <li className={'line-through'}>Visual contrast and clarity</li>
                                    <li className={'line-through'}>Support for accessibility technologies</li>
                                </ul>
                            </ul>
                            <p className="text-gray-600 mt-4">We recognize that web accessibility is a goal that is
                                never
                                finished and will continue to strive to develop a platform that best serves all
                                users.</p>
                            <p className="text-gray-600 mt-1 text-xs">Initially Posted: August 10, 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Commitments;